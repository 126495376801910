body {
  margin: 0;
  overflow-y: auto;
  /* overflow-x: hidden; */
}
body::-webkit-scrollbar {
  width: 8px;
}
body::-webkit-scrollbar-track {
  background-color: black;
}
body::-webkit-scrollbar-thumb {
  background-color: #ffc720;
}
.cursor-pointer {
  cursor: pointer;
}
.text-decoration-none {
  text-decoration: none;
}
button:disabled {
  cursor: not-allowed !important;
}

.mantine-Pagination-control[data-active] {
  background-color: #f4c924 !important;
  color: #000 !important;
  border: none !important;
}
.mantine-Pagination-control:not(:first-child):not(:last-child):hover {
  color: #000 !important;
  background-color: #f5df78 !important;
}
.mantine-Pagination-control.mantine-UnstyledButton-root:first-child:after {
  content: "Prev";
}

.mantine-Pagination-control.mantine-UnstyledButton-root:last-child:before {
  content: "Next";
}
.mantine-Pagination-control.mantine-UnstyledButton-root {
  position: relative;
  opacity: 1;
  background-color: #fff;
  font-weight: bold;
  padding: 18px 16px;
}
.mantine-Pagination-control.mantine-UnstyledButton-root:first-child {
  background-color: #000 !important;
  color: #fff;
}
.mantine-Pagination-control.mantine-UnstyledButton-root:last-child {
  background-color: #000 !important;
  color: #fff;
}
.p-2rem {
  padding: 2rem;
}
.mantine-datatable-header-cell-sortable-text {
  font-size: 14px !important;
}

.prompt-box-left-pre {
  width: 70%;
}

.prompt-box-left-pre .data-text {
  font-size: 13px;
  text-align: left;
  color: #fbc926;
}
.prompt-box-left-pre .prompt-message {
  padding: 10px;
  margin-bottom: 0;
  border-radius: 10px;
  background-color: white;
  border: 2px solid #fbc926;
  color: #fbc926;
}

/* Imported stylesheets */
@import url("https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Smooch+Sans:wght@100;200;300;400;500;600;700;800;900&family=Sora:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/* pages css files */
@import "styles/pages/auth.css";

/* components css files */
@import "styles/components/login.css";
@import "styles/components/register.css";
@import "styles/components/auth/termsAndCondition.css";
@import "styles/components/layout/navbar.css";
@import "styles/components/layout/footer.css";
@import "styles/components/home/heroSection.css";
@import "styles/components/home/videoSection.css";
@import "styles/components/home/advertiseVideo.css";
@import "styles/components/home/aboutUs.css";
@import "styles/components/home/topWinner.css";
@import "styles/components/card/topWinner.css";
@import "styles/components/home/faqs.css";
@import "styles/components/auth/register.css";
@import "styles/components/modal/generalModal.css";
@import "styles/components/card/quizVideo.css";
@import "styles/pages/quizHub/quizHub.css";
@import "styles/components/leaderBoard/leaderBoard.css";
@import "styles/pages/leardBoard/leaderBoard.css";
@import "styles/components/card/leaderBoardCard.css";
@import "styles/pages/myQuiz/myQuiz.css";
@import "styles/pages/quizDetail/quizDetails.css";
@import "styles/pages/startQuiz/startQuiz.css";
@import "styles/pages/profileSetting/profileSetting.css";
@import "styles/components/layout/sidebar.css";
@import "styles/components/layout/dashboardNavbar.css";
@import "styles/components/card/dashboardCard.css";
@import "styles/pages/dashboardPages/dashboard/dashboard.css";
@import "styles/components/card/dashboardQuizVideo.css";
@import "styles/pages/dashboardPages/dashboard/courseManagement.css";
@import "styles/components/card/dashboardLeaderCard.css";
@import "styles/pages/dashboardPages/dashboard/setting.css";
@import "styles/pages/dashboardPages/dashboard/addCourse.css";
@import "styles/pages/dashboardPages/dashboard/courseDetails.css";
@import "styles/pages/dashboardPages/dashboard/checkQuiz.css";
@import "styles/components/layout/newsLetter.css";
@import "styles/pages/usdtExchange/usdtExchange.css";
@import "styles/pages/buyUsdt/buyUsdt.css";
@import "styles/pages/orderHistory/orderHistory.css";
@import "styles/components/aiChat/chatbox.css";
@import "styles/components/aiChat/chatContext.css";
@import "styles/pages/dashboardPages/dashboard/singleChat.css";
@import "bootstrap.css";
